export const LAYOUT_MAIN = "layout-main";
export const LAYOUT_FREETOUR = "layout-freetour";
export const LAYOUT_BLANK = "layout-blank";
export const LAYOUT_LADIES = "layout-ladies";
export const LAYOUT_LADIES_V2 = "layout-ladies-v2";
export const LAYOUT_V2 = "layout-v2";
export const LAYOUT_V2_BLOG = "layout-v2-blog";
export const ROUTE_SCROLL_DELAY = 100;
export const NOT_ENOUGH_MONEY_CLOSE_REASON = -1;
export const HAS_RUNNING_CHAT_CLOSE_REASON = -2;

export const COMPANY_EFC = "efc";
export const COMPANY_EEI = "eei";
export const COMPANY_COMBINATOR = "combinator";

export const EU_COUNTRIES = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SL",
  "ES",
  "SE",
  "GB",
];

export const IMLIVE_CLOSE_REASONS = Object.freeze({
  CHAT_CLOSED_REASON_BY_USER: 202,
  CHAT_CLOSED_REASON_OFFLINE: 5,
  CHAT_CLOSED_REASON_CHANGE: 7,
  CHAT_CLOSED_REASON_PAID_CHAT: 6,
  CHAT_LEAVING_REASON_NO_CREDIT: 17,
  CHAT_LEAVING_REASON_NO_CREDIT_FROM_HOST: 88,
});

export const VISITX_CLOSE_REASONS = Object.freeze({
  CHAT_LEAVING_REASON_VIDEOTIMELIMIT: 501,
  CHAT_LEAVING_REASON_CLIENTLOGOUT: 101,
  CHAT_LEAVING_REASON_CLIENTDESTRUCT: 102,
  CHAT_LEAVING_REASON_CLIENTEXITWIN: 103,
  CHAT_LEAVING_REASON_HOSTKICK: 201,
  CHAT_LEAVING_REASON_HOSTSTOPSEND: 202,
  CHAT_LEAVING_REASON_HOSTLOGOUT: 203,
});

export const IMLIVE_SORT_FREE_CHAT = "free_chat";

export const SUBSCRIPTION_TYPE_TIMEUNIT_DAY = "DAY";
export const SUBSCRIPTION_TYPE_TIMEUNIT_MONTH = "MONTH";

export const VIDEO_SEARCH_CATEGORY_MODEL = "model";
export const VIDEO_SEARCH_CATEGORY_TAG = "tag";
export const VIDEO_SEARCH_CATEGORY_GALLERY = "gallery";
export const VIDEO_SEARCH_TYPE_TAG_PROFILE = "tag_profile";
export const VIDEO_SEARCH_TYPE_MODEL = "query";

export const PLAYDATE_EXTEND_MIN_DURATION_MINUTES = 15;

export const MESSAGE_TYPE_CAMERA = "camera_message";
export const MESSAGE_TYPE_ERSTIES = "ersties";
export const MESSAGE_TYPE_PLAYDATE_OFFLINE = "playdate_offline_message";

export const PLAYDATE_FEEDBACK_MIN_POSITIVE_RATING = 4;
export const PLAYDATE_FEEDBACK_FINAL_STEP = 5;

export const FAVORITE_TYPE_CAMERA = "camera";
export const FAVORITE_TYPE_VIDEO = "video";
export const FAVORITE_TYPE_MODEL = "model";
export const FAVORITE_TYPE_GALLERY = "gallery";
export const FAVORITE_TYPE_IMAGE = "image";

export const ENV_LOCAL = "local";
export const ENV_STAGING = "staging";
export const ENV_PRODUCTION = "production";

export const DISABLED_ROLES_COOKIE = process.env.VUE_APP_ENV + "_disabled_roles";
export const APPROVED_COOKIES_COOKIE = "approved_cookies";
export const APPROVED_AGE_COOKIE = "approved_age";
export const PROFILE_FOLDED_COOKIE = "profile_menu_folded";
export const SUCCESS_BUY_COOKIE = "success_buy";

export const ACTION_BUY_SUBSCRIPTION = "buy_subscription";
export const ACTION_CHARGE_COINS = "charge_coins";
export const ACTION_MODEL_TIP = "model_tip";
export const TYPE_PLAYDATE_REQUEST = "playdate_request";
export const TYPE_PLAYDATE_PROLONGATION = "playdate_prolongation";

export const BUY_POPUP_PLAYDATE_JOIN = "playdate_join";
export const BUY_POPUP_PLAYDATE_ASK = "playdate_ask";

export const PLAYDATE_TYPE_PUBLIC = "public";
export const PLAYDATE_TYPE_PRIVATE = "private";

export const TRANSACTIONAL_GROUP_ADVERTISEMENT = "advertisement";
export const TRANSACTIONAL_GROUP_FEEDBACK = "feedback";
export const TRANSACTIONAL_GROUP_PUB_REMINDERS = "pub_reminders";
export const TRANSACTIONAL_GROUP_PR_REMINDERS = "pr_reminders";
export const TRANSACTIONAL_GROUP_PR_REQUESTING = "pr_requesting";

export const POPUP_DOUBLE_OPT_IN = "double-opt-in";
export const POPUP_DOUBLE_OPT_IN_FINISH = "double-opt-in-finish";
export const POPUP_BUY_SUBSCRIPTION = "buy-subscription";
export const POPUP_FEEDBACK = "feedback";
export const POPUP_FEEDBACK_SUCCESS = "feedback-success";
export const POPUP_FEEDBACK_REMOVAL = "feedback-removal";
export const POPUP_COINSCHARGE = "buy-coins";
export const POPUP_COINSCHARGE_SUCCESS = "buy-coins-success";
export const POPUP_PLAYDATE_FEEDBACK = "playdate-feedback";
export const POPUP_SIGNUP = "signup";
export const POPUP_TOURPAGE_GALLERY = "tourpage-gallery";
export const POPUP_COOKIES = "cookies";
export const POPUP_AGE_APPROVAL = "age-approval";
export const POPUP_PLAYDATE_FORBIDDANCE = "playdate-forbiddance";
export const POPUP_PLAYDATE_REQUEST_SUCCESS = "playdate-request-success";
export const POPUP_TRANSACTIONAL = "transactional";
export const POPUP_REDDIT_DISCOUNT = "reddit-discount";
export const POPUP_FREECHAT = "freechat";
export const POPUP_ORGANIC = "organic";
export const POPUP_CHANGE_AVATAR = "change-avatar";
export const POPUP_FEATURE_FEEDBACK = "feature-feedback";
export const POPUP_MODEL_TIP = "model-tip";
export const POPUP_MODEL_TIP_SUCCESS = "model-tip-success";
export const POPUP_MODEL_TIP_OFFER = "model-tip-offer";
export const POPUP_FEATURE_INTRODUCE = "feature-introduce";
export const POPUP_BLACK_FRIDAY_DISCOUNT = "black-friday-discount";
export const POPUP_VALENTINES_DISCOUNT = "valentines-discount";

export const EXISTING_POPUPS = [
  POPUP_DOUBLE_OPT_IN,
  POPUP_DOUBLE_OPT_IN_FINISH,
  POPUP_BUY_SUBSCRIPTION,
  POPUP_FEEDBACK,
  POPUP_FEEDBACK_SUCCESS,
  POPUP_FEEDBACK_REMOVAL,
  POPUP_COINSCHARGE,
  POPUP_COINSCHARGE_SUCCESS,
  POPUP_PLAYDATE_FEEDBACK,
  POPUP_SIGNUP,
  POPUP_TOURPAGE_GALLERY,
  POPUP_COOKIES,
  POPUP_AGE_APPROVAL,
  POPUP_PLAYDATE_FORBIDDANCE,
  POPUP_PLAYDATE_REQUEST_SUCCESS,
  POPUP_TRANSACTIONAL,
  POPUP_REDDIT_DISCOUNT,
  POPUP_FREECHAT,
  POPUP_ORGANIC,
  POPUP_CHANGE_AVATAR,
  POPUP_FEATURE_FEEDBACK,
  POPUP_MODEL_TIP,
  POPUP_MODEL_TIP_SUCCESS,
  POPUP_MODEL_TIP_OFFER,
];

export const V2_HEADER_THEME_DARK = "dark";
export const V2_HEADER_THEME_NUDE = "nude";
export const V2_HEADER_THEME_PEACH = "peach";

export const ROLE_RETAGGER_TOKEN = "Retagger";

export const USER_AVATAR_TYPE_OLD = 0;

export const FEATURE_MODEL_TIP = "model_tip";
export const FEATURE_FEEDS = "feeds";
export const FEATURE_FREECHAT = "freechat";
export const FEATURE_ORGANIC_POPUP = "organic_popup";
export const FEATURE_CAMERA_TEASER = "camera_teaser";
export const FEATURE_FEEDBACK = "feedback";
export const FEATURE_SHOOTS = "shoots";

export const FEED_SUBTYPE_VIDEO = "video";
export const FEED_SUBTYPE_UPDATE = "update";
export const FEED_SUBTYPE_MODEL = "model";
export const FEED_SUBTYPE_GALLERY = "gallery";

export const FEED_TYPE_TOP_RATED = "top_rated";
export const FEED_TYPE_NEWCOMERS = "newcomers";
export const FEED_TYPE_TRENDING = "trending";
export const FEED_TYPE_OUR_FAVORITES = "our_favorites";
export const FEED_TYPE_NEW_STUFF = "new_stuff";
export const FEED_TYPE_FOR_YOU = "for_you";
export const FEED_TYPE_CONTINUE = "continue";
export const FEED_TYPE_FAV_MODELS = "fav_models";
export const FEED_TYPE_FAV_GALLERIES = "fav_galleries";
export const FEED_TYPE_FAV_VIDEOS = "fav_videos";
export const FEED_TYPE_MIGHT_LIKE = "might_like";
